import './App.css';
import React,{ useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ZoomMtg } from '@zoom/meetingsdk';
import HeaderComponent from './HeaderComponent';
const baseUrl = 'https://coachgate.com';


function CoachComponent() {

  let{lang, meetingRef} = useParams();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    checkMeetingStatus();
    // Cleanup function using leaveMeeting
    return () => {
      // ZoomMtg.leaveMeeting();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initMeeting = (meetingId) =>{
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    
    ZoomMtg.init({
      leaveUrl: baseUrl + '/' + lang +'/coach/session/'+ meetingRef,
      patchJsMedia: true,
      disableZoomLogo: true,
      success: async (success) => {
        
        let signature = (await (await generateHostSignature()).json()).signature;
        let meetingData = (await (await getMeetingData()).json()).data;

        ZoomMtg.join({
          sdkKey: 'vNUfywygSpS8RNCf8QAeyA',
          signature: signature,
          meetingNumber: meetingId,
          passWord: '12345',
          userName: `${meetingData.coach.first_name} ${meetingData.coach.last_name}`,
          userEmail: meetingData.coach.zoom_email??'Coach@coachgate.net',
          // role: 1, // 0 for participant, 1 for host
          success: (success) => {
            console.log('Join Meeting Success ', success);
          },
          error: (error) => {
            console.log('Join Meeting Error ', error);
          },
        })
      },
      error: (error) => {
        console.log('Init Error ', error);
      }
    });
  }


  const checkMeetingStatus = () =>{
    fetch(`${baseUrl}/api/coach/check_meeting_status`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        lang: lang,
        meetingRef: meetingRef
      })
    })
    .then(response=>response.json())
    .then(response => {
      if(response.meetingstatus === 'new' || response.meetingstatus === 'old'){
        startMeeting();
      }else{
        setErrorMessage('Meeting is not available');
      }
    })
  }

  const getMeetingData = () =>{
    return fetch(`${baseUrl}/api/coach/get_meeting_data/${meetingRef}`,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
    })
  }

  const generateHostSignature = () =>{
    return fetch(`${baseUrl}/api/coach/generate_host_signature`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        lang: lang,
        meetingRef: meetingRef
      })
    })
    
  }

  const startMeeting = () =>{
    fetch(`${baseUrl}/api/coach/start_meeting`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        lang: lang,
        meetingRef: meetingRef
      })
    })
    .then(response=>response.json())
    .then(response => {
      if(response.message){
        setErrorMessage(response.message)
      }else{
        initMeeting(response.id)
      }
    })
  }

  
  return (
    <HeaderComponent errorMessage={errorMessage} hasBackbutton={errorMessage?true:false}/>

     );
};

export default CoachComponent;
