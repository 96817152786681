import './App.css';
import React from 'react';
import { Button, Card, Flex, Typography } from 'antd';
import logo from './assets/logo.jpg';
const baseUrl = 'https://coachgate.com';



const cardStyle = {
  width: '100%',
};
const imgStyle = {
  display: 'block',
  width: 300,
  alignSelf: 'center',
  marginVertical: 20,
  marginHorizontal: 20,
  marginStart: 20,
};

function HeaderComponent({errorMessage,hasBackbutton}) {
  
  return (
    <Card
    hoverable
    style={cardStyle}
    styles={{
      body: {
        padding: 20,
        overflow: 'hidden',
      },
    }}
  >
    <Flex justify="space-between">
      <a href={baseUrl}>
      <img
        alt="avatar"
        // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        src={logo}
        style={imgStyle}
      />
      </a>
      <Flex
        vertical
        align="flex-end"
        justify="space-between"
        style={{
          padding: 0,

        }}
      >
        <Typography.Title level={3}>
          {errorMessage}
        </Typography.Title>
        {
          hasBackbutton && <Button type="primary" href={baseUrl} >
          Return To site
        </Button>
        }
        
      </Flex>
    </Flex>
  </Card>
     );
};

export default HeaderComponent;
